import store from '@/store/index';

export const permissionGroups = {
	ReportingNewsCrude: 'ReportingNewsCrude',
	CarInfo: 'CarInfo',
	CompanyEdit: 'CompanyEdit',
	Listing: 'Listing',
	FaqEdit: 'FaqEdit',
	ListingContact: 'ListingContact',
	CompanyPage: 'CompanyPage',
};

export const permissionMethods = {
	GetGridData: 'GetGridData',
	GetPreset: 'GetPreset',
	GetActiveGridData: 'GetActiveGridData',
	GetCompletedGridData: 'GetCompletedGridData',
	GetCancelledGridData: 'GetCancelledGridData',
};

export const checkPermission = ({ groupName, methodName }) => {
	const { permissions } = store.getters;
	if (!groupName || !permissions) return false;

	const group = groupName.toLowerCase();
	const method = methodName?.toLowerCase();

	return permissions.some((permission) => {
		const groupMatch = permission.group.toLowerCase() === group;
		const methodMatch = method ? permission.name.toLowerCase() === method : true;
		return groupMatch && methodMatch;
	});
};

const createSubMenuItem = (action, title, route, groupName, viewMethodName = null) => ({
	action,
	title,
	route,
	groupName,
	viewMethodName,
	active: false,
});

const boMenuItems = [
	{
		action: 'fas fa-hand',
		title: 'Müşteri Talepleri',
		active: false,
		open: true,
		subMenuItems: [
			createSubMenuItem(
				'fas fa-hourglass-start',
				'Aktif',
				'requests',
				permissionGroups.CarInfo,
				permissionMethods.GetActiveGridData,
			),
			createSubMenuItem(
				'fas fa-list-check',
				'Tamamlanan',
				'completedRequest',
				permissionGroups.CarInfo,
				permissionMethods.GetCompletedGridData,
			),
			createSubMenuItem(
				'fas fa-xmark',
				'İptal Edilen',
				'cancelledRequest',
				permissionGroups.CarInfo,
				permissionMethods.GetCancelledGridData,
			),
		],
	},
	{
		action: 'fab fa-microsoft',
		title: 'Listing',
		active: false,
		open: true,
		subMenuItems: [
			createSubMenuItem(
				'fas fa-list',
				'Araç Listesi',
				'carList',
				permissionGroups.Listing,
				permissionMethods.GetGridData,
			),
			createSubMenuItem(
				'fas fa-message',
				'Mesajlar',
				'messages',
				permissionGroups.ListingContact,
				permissionMethods.GetGridData,
			),
			createSubMenuItem(
				'fas fa-calendar-check',
				'Opsiyonlu Araç Listesi',
				'reserveCarList',
				permissionGroups.ListingContact,
				permissionMethods.GetGridData,
			),
		],
	},
	{
		action: 'fas fa-cog',
		title: 'Genel Ayarlar',
		active: false,
		open: true,
		subMenuItems: [
			createSubMenuItem('fas fa-file', 'Sayfalar', 'companyPageList', permissionGroups.CompanyPage),
			createSubMenuItem('fas fa-circle-info', 'Firma Bilgileri', 'companyEdit', permissionGroups.CompanyEdit),
			createSubMenuItem('fas fa-clock', 'Çalışma Saatleri', 'workHoursEdit', permissionGroups.CompanyEdit),
			createSubMenuItem('fas fa-phone-slash', 'Kara Liste', 'blackListEdit', permissionGroups.CompanyEdit),
			createSubMenuItem('fas fa-wrench', 'Site Ayarları', 'siteEdit', permissionGroups.CompanyEdit),
			createSubMenuItem('fas fa-palette', 'Tema Ayarları', 'themeEdit', permissionGroups.CompanyEdit),
			createSubMenuItem(
				'fas fa-arrow-up-right-from-square',
				'Dış Bağlantı Ayarları',
				'connectionEdit',
				permissionGroups.CompanyEdit,
			),
			createSubMenuItem('fas fa-share-nodes', 'Sosyal Medya', 'socialMediaEdit', permissionGroups.CompanyEdit),
			createSubMenuItem('fas fa-question', 'Sıkça Sorulan Sorular', 'faqEdit', permissionGroups.CompanyEdit),
			createSubMenuItem('fas fa-circle-nodes', 'Süreçler', 'businessStepEdit', permissionGroups.CompanyEdit),
			createSubMenuItem('fas fa-link', 'Linkler', 'quickLinkEdit', permissionGroups.CompanyEdit),
			createSubMenuItem(
				'fas fa-wand-sparkles',
				'Fiyatlama Sihirbazı Ayarları',
				'wizardEdit',
				permissionGroups.CompanyEdit,
			),
			createSubMenuItem('fas fa-file-lines', 'Kredi Bilgileri', 'creditEdit', permissionGroups.CompanyEdit),
			createSubMenuItem(
				'fas fa-calendar-check',
				'Araç Opsiyonlama',
				'carReservationStepEdit',
				permissionGroups.CompanyEdit,
			),
			createSubMenuItem('fas fa-location-dot', 'Lokasyonlar', 'companyLocationEdit', permissionGroups.CompanyEdit),
		],
	},
];

export const menuItems = [...boMenuItems];

export default {
	permissionGroups,
	permissionMethods,
	checkPermission,
	menuItems,
};
