/* eslint-disable no-param-reassign */
import CloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

import { capitalize, hexToRgb, sleep, toSlug, toTitleCase } from '@/common/utils';

const utilities = {
	$cloneDeep: CloneDeep,
	$debounce: debounce,
	$sleep: sleep,
	$toTitleCase: toTitleCase,
	$hexToRgb: hexToRgb,
	$capitalize: capitalize,
	$toSlug: (value, delimiter = '-') => toSlug(value, delimiter),
};

const GlobalFunctions = {
	install(Vue) {
		Object.entries(utilities).forEach(([key, func]) => {
			Vue.prototype[key] = func;
		});
	},
};

export default GlobalFunctions;
