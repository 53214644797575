/* eslint-disable no-unused-vars */
import VueMask from 'v-mask';
import VueSocialSharing from 'vue-social-sharing';
import VuetifyMask from 'vuetify-mask';

const plugins = [VuetifyMask, VueMask, VueSocialSharing];

const GlobalPlugins = {
	install(Vue) {
		plugins.forEach((plugin) => Vue.use(plugin));
	},
};

export default GlobalPlugins;
