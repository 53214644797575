import MessageDialog from '@/components/_shared/MessageDialog.vue';
import SnackBarMessage from '@/components/_shared/SnackBarMessage.vue';
import VueApexCharts from 'vue-apexcharts';

const components = [
	{ name: SnackBarMessage.name, component: SnackBarMessage },
	{ name: MessageDialog.name, component: MessageDialog },
	{ name: 'apexchart', component: VueApexCharts },
];

const GlobalComponents = {
	install(Vue) {
		components.forEach(({ name, component }) => {
			Vue.component(name, component);
		});
	},
};

export default GlobalComponents;
