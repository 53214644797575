/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/lib/services/goto';

import RouteManager from '@/common/routeManager';

import { checkPermission, permissionGroups } from '@/common/pageUtils';
import store from '@/store/index';

const ifNotAuthenticated = (to, from, next) => {
	if (!store.getters.token) {
		next();
		return;
	}
	next('/');
};

const ifAuthenticated = (to, from, next) => {
	if (store.getters.token) {
		next();
		return;
	}
	next('/kullanici-girisi');
};

const hasListingPermission = (to, from, next) => {
	next();
	// store.dispatch('initCompany').finally(() => {
	// 	if (store.getters.hasListing) {
	// 		next();
	// 		return;
	// 	}
	// 	next('/');
	// });
};

Vue.use(VueRouter);

RouteManager.initialize(store.getters.getPages);

const dynamicScrollRoutes = RouteManager.scrollRoutes;
const dynamicFullRoutes = RouteManager.fullRoutes;
const listing = RouteManager.listingRoute;

const authRoutes = [
	{
		path: '/dukkan/kullanici-girisi',
		component: () => import('@/views/AuthBase.vue'),
		children: [
			{
				name: 'login',
				meta: {
					title: 'Login',
					autoRefreshAfterToUpgrade: true,
				},
				path: '/kullanici-girisi',
				component: () => import('@/views/_shared/auth/Login.vue'),
				beforeEnter: ifNotAuthenticated,
			},
			{
				name: 'logout',
				path: '/logout',
				component: () => import('@/views/_shared/auth/Login.vue'),
				beforeEnter: ifAuthenticated,
			},
		],
	},
];

const backofficeRoutes = [
	{
		path: '/dukkan',
		component: () => import('@/views/BackofficeBase.vue'),
		beforeEnter: ifAuthenticated,
		redirect: '/dukkan/dashboard',
		children: [
			{
				path: '/dukkan/dashboard',
				name: 'dashboard',
				meta: {
					title: 'Dashboard',
					autoRefreshAfterToUpgrade: true,
				},
				component: () => import('@/views/backoffice/pages/dashboard/Home.vue'),
			},
			{
				name: 'account',
				path: '/dukkan/hesabim',
				beforeEnter: ifAuthenticated,
				meta: {
					groupName: permissionGroups.Profile,
					title: 'Account',
				},
				component: () => import('@/views/backoffice/pages/account/Index.vue'),
			},
			{
				path: '/dukkan/firma-bilgileri',
				name: 'companyEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Firma Bilgileri',
				},
				component: () => import('@/views/backoffice/pages/company/information/Index.vue'),
			},
			{
				path: '/dukkan/sayfalar',
				name: 'companyPageLayout',
				redirect: '/dukkan/sayfalar',
				meta: {
					groupName: permissionGroups.CompanyPage,
					title: 'Sayfalar',
				},
				component: () => import('@/views/backoffice/pages/company/page-contents/Layout.vue'),
				children: [
					{
						path: '/dukkan/sayfalar',
						name: 'companyPageList',
						meta: {
							groupName: permissionGroups.CompanyPage,
							title: 'Sayfalar',
						},
						component: () => import('@/views/backoffice/pages/company/page-contents/Index.vue'),
					},
					{
						path: '/dukkan/sayfalar/ekle',
						name: 'companyPageAdd',
						meta: {
							groupName: permissionGroups.CompanyPage,
							title: 'Sayfa Ekle',
						},
						component: () => import('@/views/backoffice/pages/company/page-contents/Add.vue'),
					},
					{
						path: '/dukkan/sayfalar/duzenle/:id',
						name: 'companyPageEdit',
						meta: {
							groupName: permissionGroups.CompanyPage,
							title: 'Sayfa Düzenle',
						},
						component: () => import('@/views/backoffice/pages/company/page-contents/Edit.vue'),
					},
				],
			},
			{
				path: '/dukkan/calisma-saatleri',
				name: 'workHoursEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Çalışma Saatleri',
				},
				component: () => import('@/views/backoffice/pages/company/WorkHours.vue'),
			},
			{
				path: '/dukkan/site-ayarlari',
				name: 'siteEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Site Ayarları',
				},
				component: () => import('@/views/backoffice/pages/company/Site.vue'),
			},
			{
				path: '/dukkan/sss',
				name: 'faqEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Sıkça Sorulan Sorular',
				},
				component: () => import('@/views/backoffice/pages/company/Sss.vue'),
			},
			{
				path: '/dukkan/tema',
				name: 'themeEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Tema Ayarları',
				},
				component: () => import('@/views/backoffice/pages/company/theme/Index.vue'),
			},
			{
				path: '/dukkan/baglantilar',
				name: 'connectionEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Dış Bağlantılar',
				},
				component: () => import('@/views/backoffice/pages/company/Connection.vue'),
			},
			{
				path: '/dukkan/sosyal-medya',
				name: 'socialMediaEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Sosyal Medya',
				},
				component: () => import('@/views/backoffice/pages/company/SocialMedia.vue'),
			},
			{
				path: '/dukkan/surecler',
				name: 'businessStepEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Süreçler',
				},
				component: () => import('@/views/backoffice/pages/company/BusinessSteps.vue'),
			},
			{
				path: '/dukkan/black-list',
				name: 'blackListEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Kara Liste',
				},
				component: () => import('@/views/backoffice/pages/company/blacklist/Index.vue'),
			},
			{
				path: '/dukkan/linkler',
				name: 'quickLinkEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Linkler',
				},
				component: () => import('@/views/backoffice/pages/company/QuickLinks.vue'),
			},
			{
				path: '/dukkan/arac-opsiyonlama-surecleri',
				name: 'carReservationStepEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Araç Opsiyonlama',
				},
				component: () => import('@/views/backoffice/pages/company/car-reservation/Index.vue'),
			},
			{
				path: '/dukkan/lokasyonlar',
				name: 'companyLocationEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Lokasyonlar',
				},
				component: () => import('@/views/backoffice/pages/company/locations/Index.vue'),
			},
			{
				path: '/dukkan/sihirbaz',
				name: 'wizardEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Sihirbaz Ayarları',
				},
				component: () => import('@/views/backoffice/pages/company/Wizard.vue'),
			},
			{
				path: '/dukkan/kredi-bilgileri',
				name: 'creditEdit',
				meta: {
					groupName: permissionGroups.CompanyEdit,
					title: 'Kredi Bilgileri',
				},
				component: () => import('@/views/backoffice/pages/company/Credit.vue'),
			},
			{
				path: '/dukkan/talepler',
				name: 'requests',
				meta: {
					groupName: permissionGroups.CarInfo,
					title: 'Talepler',
					autoRefreshAfterToUpgrade: true,
				},
				component: () => import('@/views/backoffice/pages/general/CarInfo.vue'),
			},
			{
				path: '/dukkan/tamamlanan-talepler',
				name: 'completedRequest',
				meta: {
					groupName: permissionGroups.CarInfo,
					title: 'Tamamlanan Talep Listesi',
					autoRefreshAfterToUpgrade: true,
				},
				component: () => import('@/views/backoffice/pages/general/CompletedRequests.vue'),
			},
			{
				path: '/dukkan/iptal-edilen-talepler',
				name: 'cancelledRequest',
				meta: {
					groupName: permissionGroups.CarInfo,
					title: 'İptal Edilen Talep Listesi',
					autoRefreshAfterToUpgrade: true,
				},
				component: () => import('@/views/backoffice/pages/general/CancelledRequests.vue'),
			},
			{
				path: '/dukkan/talepler/detay/:id',
				name: 'carInfoDetails',
				meta: {
					groupName: permissionGroups.CarInfo,
					title: 'Talep Detayları',
				},
				component: () => import('@/views/backoffice/pages/general/CarInfoDetail.vue'),
			},
			{
				path: '/dukkan/araclar',
				name: 'carList',
				meta: {
					groupName: permissionGroups.Listing,
					title: 'Araç Listesi',
				},
				component: () => import('@/views/backoffice/pages/general/CarListing.vue'),
			},
			{
				path: '/dukkan/mesajlar',
				name: 'messages',
				meta: {
					groupName: permissionGroups.ListingContact,
					title: 'Mesajlar',
				},
				component: () => import('@/views/backoffice/pages/general/ListingContactMessages.vue'),
			},
			{
				path: '/dukkan/opsiyonlu-araclar',
				name: 'reserveCarList',
				meta: {
					groupName: permissionGroups.Listing,
					title: 'Opsiyonlu Araç Listesi',
				},
				component: () => import('@/views/backoffice/pages/general/ReserveCarListing.vue'),
			},
		],
	},
];

const endUserRoutes = [
	{
		path: '',
		component: () => import('@/views/Base.vue'),
		redirect: '/',
		children: [
			...dynamicFullRoutes,
			...dynamicScrollRoutes,
			{
				path: 'anasayfa',
				redirect: '/',
			},
			{
				path: '/',
				name: 'home',
				component: () => import('@/views/enduser/pages/Main.vue'),
				meta: {
					title: 'Anasayfa',
					autoRefreshAfterToUpgrade: true,
				},
			},
			{
				path: 'teklif-al/:step?',
				name: 'get-offer',
				component: () => import('@/views/enduser/pages/Main.vue'),
				meta: {
					title: 'Hemen teklif al!',
					autoRefreshAfterToUpgrade: true,
				},
			},
		],
	},
];

const listingRoutes = [];

if (listing && listing.path && listing.name) {
	listingRoutes.push({
		path: listing.path,
		name: listing.name,
		beforeEnter: hasListingPermission,
		redirect: { name: 'listingBase' },
		component: () => import('@/modules/listing/ListingBase.vue'),
		children: [
			{
				path: ':brands?/:models?',
				name: 'listingBase',
				meta: {
					app: 'listing',
					title: 'Araçlar',
					autoRefreshAfterToUpgrade: true,
				},
				component: () => import('@/modules/listing/Index.vue'),
				props: (route) => ({
					brands: route.params.brands ? route.params.brands.split('-') : [],
					models: route.params.models ? route.params.models.split('-') : [],
				}),
			},
			{
				path: '/ilan/:url/:id',
				name: 'carDetails',
				meta: {
					app: 'listing',
					title: 'Araç Detayları',
					autoRefreshAfterToUpgrade: true,
				},
				component: () => import('@/modules/listing/pages/cars/details/Index.vue'),
			},
			{
				path: '/ilan/:url/:id/opsiyon',
				name: 'toOption',
				redirect: ':id/opsiyon/basla',
				meta: {
					app: 'listing',
					title: 'Araç Opsiyonla',
					autoRefreshAfterToUpgrade: true,
				},
				component: () => import('@/modules/listing/pages/car-reservation/Index.vue'),
				props: true,
				beforeEnter: (to, from, next) => {
					if (store.getters.hasCarReservation) {
						next();
					} else {
						next({ name: 'home' });
					}
				},
				children: [
					{
						path: 'basla',
						name: 'optionStart',
						meta: {
							app: 'listing',
							title: 'Araç Opsiyonla | Başla',
							autoRefreshAfterToUpgrade: true,
						},
						component: () => import('@/modules/listing/pages/car-reservation/start/Index.vue'),
						props: true,
					},
					{
						path: 'kisisel-bilgiler',
						name: 'optionCustomerInfo',
						meta: {
							app: 'listing',
							title: 'Araç Opsiyonla | Kişisel Bilgileriniz',
							autoRefreshAfterToUpgrade: true,
						},
						component: () => import('@/modules/listing/pages/car-reservation/customer-info/Index.vue'),
						props: true,
					},
					{
						path: 'odeme-durumu',
						name: 'optionComplete',
						meta: {
							app: 'listing',
							title: 'Araç Opsiyonla | Ödeme Durumu',
							autoRefreshAfterToUpgrade: true,
						},
						component: () => import('@/modules/listing/pages/car-reservation/complete/Index.vue'),
						props: true,
					},
				],
			},
		],
	});
}

const errorRoutes = [
	{
		path: '/hata',
		component: () => import('@/views/ErrorBase.vue'),
		redirect: '/hata/404',
		children: [
			{
				path: '/hata/404',
				name: '404',
				component: () => import('@/views/_shared/error/404.vue'),
			},
			{
				path: '/hata/401',
				name: '401',
				component: () => import('@/views/_shared/error/401.vue'),
			},
			{
				path: '/hata/403',
				name: '403',
				component: () => import('@/views/_shared/error/403.vue'),
			},
			{
				path: '/hata/503',
				name: '503',
				component: () => import('@/views/_shared/error/503.vue'),
			},
		],
	},
];

const routes = [
	...errorRoutes,
	...authRoutes,
	...backofficeRoutes,
	...listingRoutes,
	...endUserRoutes,
	{
		path: '*',
		redirect: '/hata',
	},
	{
		path: '/sitemap.xml',
		beforeEnter: (to, from, next) => {
			window.location = `${window.location.origin}/sitemap/${store.getters.getSite.companyId}.xml`;
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	scrollBehavior: (to, from, savedPosition) => {
		if (to.name !== 'get-offer') {
			store.dispatch('returnWizardStart').then(() => {
				const elementId = to.name;
				const element = document.getElementById(elementId);
				if (element) {
					goTo(element, { offset: 100 });
				}
			});
		}
	},
	routes,
});

router.beforeEach(async (to, from, next) => {
	store.dispatch('setCurrentRoute', to);

	const hasPermission = to.meta.groupName
		? await checkPermission({ groupName: to.meta.groupName, methodName: to.meta.methodName })
		: true;

	if (!hasPermission && store.getters.isAuthenticated) {
		next('/401');
		return;
	}

	if ((to.meta?.groupName ?? '').toLowerCase() === 'carinfo') {
		if (from.name !== 'carInfoDetails' && to.name !== 'carInfoDetails') {
			await store.dispatch('clearFilter', false);
			store.dispatch('setFilterActive', false);
		}
	}

	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
	next();
});

router.afterEach((to) => {
	Vue.nextTick(() => {
		if (to.name === 'get-offer') return;
		const title = to.meta.title ? ` | ${to.meta.title}` : '';

		const mainTitle = store.getters.getSite?.pageTitle
			? store.getters.getSite?.pageTitle
			: store.getters.getSite?.companyName;

		document.title = `${mainTitle || 'Site'} ${title}` || `${mainTitle}`;
	});
});

// router.beforeEach((to, from, next) => {
// 	if (to.redirectedFrom?.indexOf('sitemap.xml') > -1) {
// 		window.location = `${window.location.origin}/sitemap/${store.getters.getSite.companyId}.xml`;
// 	} else {
// 		next();
// 	}
// });

export default router;
