/* eslint-disable no-underscore-dangle */
import { pageType } from './enums';

export default class RouteManager {
	static _pages = [];

	static _internalScrollRoutes = null;

	static _internalFullRoutes = null;

	static _internalListingRoute = null;

	static _isInitialized = false;

	static initialize(pages) {
		this._pages = pages;
		this._internalScrollRoutes = [];
		this._internalFullRoutes = [];
		this._internalListingRoute = null;
		this._isInitialized = false;
		this.initializeRoutes();
	}

	static getAppName(page) {
		return page.content?.includes('{{module:listing}}')
			? 'listing'
			: page.children?.find((child) => child.content?.includes('{{module:listing}}')) || 'pricing';
	}

	static createRoute(page) {
		return {
			path: `/${page.slug}`,
			name: page.id,
			content: page.content,
			meta: {
				app: RouteManager.getAppName(page),
				title: page.name,
				autoRefreshAfterToUpgrade: true,
			},
			component: () =>
				page.type === pageType.SCROLL.id
					? import('@/views/enduser/pages/Main.vue')
					: import('@/views/enduser/pages/DynamicFullPage.vue'),
			props: page.type !== pageType.SCROLL.id ? { page } : undefined,
		};
	}

	static initializeRoutes() {
		if (this._isInitialized) return;

		const addRoute = (page) => {
			// Eğer sayfanın isExternal değeri true ise route'a dahil edilmez
			if (page.isExternal) {
				return;
			}

			const route = RouteManager.createRoute(page);

			// Only add to scrollRoutes and fullRoutes if app is 'pricing'
			if (route.meta.app === 'pricing') {
				if (page.type === pageType.SCROLL.id) {
					this._internalScrollRoutes.push(route);
				} else {
					this._internalFullRoutes.push(route);
				}
			}

			// Set listingRoute if app is 'listing'
			if (route.meta.app === 'listing' && !this._internalListingRoute) {
				this._internalListingRoute = route;
			}

			if (page.children) {
				page.children.forEach(addRoute);
			}
		};

		this._pages.forEach(addRoute);
		this._isInitialized = true;
	}

	static get scrollRoutes() {
		if (this._internalScrollRoutes === null) {
			this.initializeRoutes();
		}
		return this._internalScrollRoutes;
	}

	static get fullRoutes() {
		if (this._internalFullRoutes === null) {
			this.initializeRoutes();
		}
		return this._internalFullRoutes;
	}

	static get listingRoute() {
		if (this._internalListingRoute === null) {
			this.initializeRoutes();
		}
		return this._internalListingRoute;
	}
}
