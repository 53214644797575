/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { API_URL } from '@/common/config';
import { convertToCamelcase } from '@/common/utils';
import NProgress from '@/plugins/nProgress';
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';

/**
 * Service to call HTTP requests via Axios
 */
class HttpService {
	constructor() {
		this.errorHandler = null;
		this.customHeaders = null;
		this.$store = null; // Store will be initialized later
	}

	init(store) {
		this.$store = store;
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = API_URL;

		this._initializeRequestInterceptor();
		this._initializeResponseInterceptor();
	}

	/**
	 * Set up Axios request interceptor
	 */
	_initializeRequestInterceptor() {
		Vue.axios.interceptors.request.use(
			(request) => {
				NProgress.start();
				request.data = { data: request.data }; // Wrap data in an object
				return request;
			},
			(error) => {
				NProgress.done();
				return Promise.reject(error);
			},
		);
	}

	/**
	 * Set up Axios response interceptor
	 */
	_initializeResponseInterceptor() {
		Vue.axios.interceptors.response.use(
			(response) => {
				NProgress.done();
				const { data } = convertToCamelcase(response);
				if (!data.isValid) {
					return Promise.reject(data.responseText);
				}
				return data.data;
			},
			(error) => {
				NProgress.done();
				const result = convertToCamelcase({ response: { ...error.response }, message: error.message });

				if (error.config?.errorHandle === false) {
					return Promise.reject(result);
				}

				return this.errorHandler ? this.errorHandler(result) : Promise.reject(result);
			},
		);
	}

	/**
	 * Set the default HTTP request headers
	 */
	setHeader(tokenParam = null) {
		const token = tokenParam || this.$store?.getters?.token;
		if (token) {
			Vue.axios.defaults.headers.common.Authorization = token;
		}
		this._addCustomHeaders();
	}

	/**
	 * Add custom headers if defined
	 */
	_addCustomHeaders() {
		if (typeof this.customHeaders === 'function') {
			const headers = this.customHeaders();
			headers.forEach(({ key, value }) => {
				Vue.axios.defaults.headers.common[key] = value;
			});
		}
	}

	/**
	 * General HTTP request method
	 */
	async request(method, resource, params = {}, config = {}, token = null) {
		this.setHeader(token);
		try {
			const response = await Vue.axios[method](resource, params, config);
			return response;
		} catch (error) {
			throw new Error(`[LB] ApiService ${error}`);
		}
	}

	// HTTP Methods

	query(resource, params) {
		return this.request('get', resource, { params });
	}

	get(resource, slug = '') {
		return this.request('get', `${resource}/${slug}`);
	}

	post(resource, params, config, token) {
		return this.request('post', resource, params, config, token);
	}

	update(resource, slug, params) {
		return this.request('put', `${resource}/${slug}`, params);
	}

	put(resource, params) {
		return this.request('put', resource, params);
	}

	delete(resource) {
		return this.request('delete', resource);
	}
}

export default new HttpService();
