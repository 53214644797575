<template>
	<v-app>
		<v-snackbar v-model="updateExists" :timeout="-1">
			New version available! Click to update
			<template v-slot:action="{ attrs }">
				<v-btn color="green" text v-bind="attrs" @click="refreshApp"> Update </v-btn>
			</template>
		</v-snackbar>
		<nprogress-container />
		<SnackBarMessage />
		<message-dialog />
		<router-view />
	</v-app>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'App',
	components: { NprogressContainer },
	data() {
		return {
			autoRefreshAfterToUpgrade: false,
			refreshing: false,
			registration: null,
			updateExists: false,
			snackbar: false,
			loaded: false,
		};
	},
	computed: {
		...mapGetters(['siteStatus']),
	},
	created() {
		this.registerServiceWorkerEvents();
	},
	beforeDestroy() {
		document.removeEventListener('swUpdated', this.processRefreshUI);
	},
	methods: {
		registerServiceWorkerEvents() {
			document.addEventListener('swUpdated', this.processRefreshUI, { once: true });
			if (navigator.serviceWorker) {
				navigator.serviceWorker.addEventListener('controllerchange', this.reloadOnControllerChange);
			}
		},
		reloadOnControllerChange() {
			if (!this.refreshing) {
				this.refreshing = true;
				window.location.reload();
			}
		},
		processRefreshUI(e) {
			this.registration = e.detail;
			if (this.autoRefreshAfterToUpgrade) {
				this.refreshApp();
			} else {
				this.showRefreshUI();
			}
		},
		showRefreshUI() {
			this.updateExists = true;
		},
		refreshApp() {
			this.updateExists = false;
			if (this.registration && this.registration.waiting) {
				this.registration.waiting.postMessage('skipWaiting');
			}
		},
	},
	watch: {
		$route: {
			handler(newValue) {
				this.autoRefreshAfterToUpgrade = newValue.meta.autoRefreshAfterToUpgrade ?? false;
				if (this.autoRefreshAfterToUpgrade) this.refreshApp();
			},
		},
	},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

* {
	font-family: 'Questrial', sans-serif;
}

.v-application .text-h1,
.v-application .text-h2,
.v-application .text-h3,
.v-application .text-h4,
.v-application .text-h5,
.v-application .text-h6,
.v-application .text-subtitle-1,
.v-application .text-subtitle-2 {
	font-family: 'Questrial', sans-serif !important;
}

html,
input,
select,
.v-label,
.v-input__control {
	font-size: 14px;
}

#app {
	overflow: hidden;
}

#nprogress .bar {
	background: rgb(255, 62, 87) !important;
}

/* Scrollbar styles */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #9e9e9e;
}

::-webkit-scrollbar-thumb {
	background: #6d6d6d;
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(58, 58, 58);
}

.theme--light ::-webkit-scrollbar-track {
	background: #e6e6e6;
}

.theme--light ::-webkit-scrollbar-thumb {
	background: #b0b0b0;
	border-radius: 7px;
}

.theme--light ::-webkit-scrollbar-thumb:hover {
	background: rgb(104, 104, 104);
}

.theme--dark ::-webkit-scrollbar-track {
	background: #3a3a3a;
}

.theme--dark ::-webkit-scrollbar-thumb {
	background: #555555;
	border-radius: 7px;
}

.theme--dark ::-webkit-scrollbar-thumb:hover {
	background: rgb(78, 78, 78);
}
</style>
