/* eslint-disable no-empty */
import localforage from 'localforage';
import SecureLS from 'secure-ls';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth.module';
import carInfo from './modules/carInfo.module';
import carWizard from './modules/carWizard.module';
import common from './modules/common.module';
import company from './modules/company.module';
import dashboard from './modules/dashboard.module';
import grid from './modules/grid.module';
import listing from './modules/listing.module';
import listingContact from './modules/listingContact.module';
import messageDialog from './modules/messageDialog.module';
import pages from './modules/pages.module';
import preview from './modules/preview.module';
import sideBar from './modules/sideBar.module';
import snackBar from './modules/snackBar.module';
import userVerification from './modules/userVerification.module';

import heartbeat from './modules/heartbeat.module';

const ls = new SecureLS({ isCompression: true });

Vue.use(Vuex);

const localForageStore = localforage.createInstance({
	name: 'lead-gen-storage',
	storeName: 'vuex_store',
	description: 'Storage for Vuex persisted state',
});

const customStorage = {
	getItem: async (key) => {
		try {
			return await localForageStore.getItem(key);
		} catch (ex) {
			console.error(`Error fetching key "${key}" from localforage`, ex);
			return null;
		}
	},
	setItem: async (key, value) => {
		try {
			await localForageStore.setItem(key, value);
		} catch (ex) {
			console.error(`Error saving key "${key}" to localforage`, ex);
		}
	},
	removeItem: async (key) => {
		try {
			await localForageStore.removeItem(key);
		} catch (ex) {
			console.error(`Error removing key "${key}" from localforage`, ex);
		}
	},
};

const authPersistedStateConfigs = {
	key: 'lead-gen-auth',
	paths: ['auth', 'sideBar'],
	reducer(state) {
		if (state.auth.user === null) return {};
		return { auth: state.auth, sideBar: state.sideBar };
	},
	storage: {
		getItem: (key) => {
			let storeData = '';
			try {
				storeData = ls.get(key);
			} catch (ex) {}

			return storeData;
		},
		setItem: (key, value) => {
			const data = JSON.parse(value);
			if (data.auth) ls.set(key, value);
		},
		removeItem: (key) => ls.remove(key),
	},
};

const carWizardPersistedStateConfigs = {
	key: 'carWizard',
	paths: ['carWizard'],
	storage: window.sessionStorage,
};

const companyPersistedStateConfigs = {
	key: 'lead-gen-company',
	paths: ['company'],
	storage: window.sessionStorage,
};

const previewPersistedStateConfigs = {
	key: 'lead-gen-preview',
	paths: ['preview'],
	storage: window.sessionStorage,
};

const favoriteCarPersistedStateConfigs = {
	key: 'lgfc',
	paths: ['listing.favoriteCars'],
	storage: window.localStorage,
};

// eslint-disable-next-line no-unused-vars
const carFilterPresetPersistedStateConfigs = {
	key: 'lgcf',
	paths: ['listing.filter.preset'],
	storage: customStorage,
};

export default new Vuex.Store({
	modules: {
		common,
		preview,
		auth,
		company,
		carWizard,
		carInfo,
		dashboard,
		sideBar,
		snackBar,
		messageDialog,
		heartbeat,
		userVerification,
		listing,
		listingContact,
		grid,
		pages,
	},
	plugins: [
		createPersistedState(authPersistedStateConfigs),
		createPersistedState(companyPersistedStateConfigs),
		createPersistedState(previewPersistedStateConfigs),
		createPersistedState(carWizardPersistedStateConfigs),
		createPersistedState(favoriteCarPersistedStateConfigs),
		createPersistedState(carFilterPresetPersistedStateConfigs),
	],
});
