import ApiService from '@/common/api.service';

export const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM';
export const SMS_VALIDATION = 'SMS_VALIDATION';
export const APPROVE_VALIDATION_CODE = 'APPROVE_VALIDATION_CODE';

export const FETCH_MESSAGE_GRID_DATA = 'FETCH_MESSAGE_GRID_DATA';
export const DELETE_CONTACT_MESSAGE = 'DELETE_CONTACT_MESSAGE';
export const CHANGE_CONTACT_MESSAGE_STATUS = 'CHANGE_CONTACT_MESSAGE_STATUS';

export const SET_VALIDATION = 'SET_VALIDATION';

const endPoints = {
	create: 'listingcontact/create',
	smsValidation: 'listingcontact/sendvalidationcode',
	approveValidationCode: 'listingcontact/approvevalidationcode',
	getGridData: 'listingcontact/getgriddata',
	setStatus: 'listingcontact/setstatus',
	delete: 'listingcontact/delete',
};

const getDefaultState = () => ({
	messageGrid: {
		loading: false,
		data: [],
		count: 0,
	},
	validation: {
		smsKey: '',
		validationTime: 0,
	},
});

const state = {
	...getDefaultState(),
};

const getters = {
	messageGrid: (state) => state.messageGrid,
	validation: (state) => state.validation,
};

const actions = {
	[SMS_VALIDATION](context, contactForm) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.smsValidation, contactForm)
				.then((response) => {
					context.commit(SET_VALIDATION, {
						smsKey: response.smsKey,
						validationTime: response.validationTime,
					});
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[APPROVE_VALIDATION_CODE](context, { validationCode, contactForm }) {
		return new Promise((resolve, reject) => {
			const requestPayload = {
				smsKey: context.state.validation.smsKey,
				smsCode: validationCode,
			};

			ApiService.post(endPoints.approveValidationCode, requestPayload)
				.then(async () => {
					await context.dispatch(SEND_CONTACT_FORM, contactForm);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[SEND_CONTACT_FORM](context, contactForm) {
		return new Promise((resolve, reject) => {
			const payload = { ...contactForm };

			ApiService.post(endPoints.create, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[DELETE_CONTACT_MESSAGE](context, payload) {
		return new Promise((resolve, reject) => {
			const requestPayload = { id: payload.id, listingKey: payload.listingKey };
			ApiService.post(endPoints.delete, requestPayload)
				.then((response) => {
					context.commit(DELETE_CONTACT_MESSAGE, payload);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[CHANGE_CONTACT_MESSAGE_STATUS](context, payload) {
		return new Promise((resolve, reject) => {
			const newStatus = payload.status.toLowerCase() === 'yeni' ? 2 : 1;
			const newStatusText = payload.status.toLowerCase() === 'yeni' ? 'Cevaplandı' : 'Yeni';

			const requestPayload = {
				id: payload.id,
				listingKey: payload.listingKey,
				status: newStatus,
			};

			ApiService.post(endPoints.setStatus, requestPayload)
				.then((response) => {
					context.commit(CHANGE_CONTACT_MESSAGE_STATUS, {
						...payload,
						status: newStatusText,
					});
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[FETCH_MESSAGE_GRID_DATA](context, payload) {
		state.messageGrid.loading = true;
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getGridData, payload)
				.then((response) => {
					context.commit(FETCH_MESSAGE_GRID_DATA, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					state.messageGrid.loading = false;
				});
		});
	},
};

const mutations = {
	[FETCH_MESSAGE_GRID_DATA](state, response) {
		state.messageGrid.data = response.data;
		state.messageGrid.count = response.totalRecordCount;
	},
	[DELETE_CONTACT_MESSAGE](state, payload) {
		const index = state.messageGrid.data.indexOf(payload);
		if (index > -1) {
			state.messageGrid.data.splice(index, 1);
			state.messageGrid.count = -1;
		}
	},
	[CHANGE_CONTACT_MESSAGE_STATUS](state, payload) {
		const index = state.messageGrid.data.findIndex((x) => x.id === payload.id);
		if (index > -1) {
			state.messageGrid.data.splice(index, 1, payload);
		}
	},
	[SET_VALIDATION](state, { smsKey, validationTime }) {
		state.validation.smsKey = smsKey;
		state.validation.validationTime = validationTime;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
