import { ASSET_PATH, BUCKET_URL } from '@/common/globals';
import i18n from '@/plugins/i18n';
import createVuetify from '@/plugins/vuetify';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

import initSite from '@/common/siteInit';

import { sync } from 'vuex-router-sync';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';

import ApiService from './common/api.service';
import GlobalComponents from './common/globalComponents';
import GlobalFilters from './common/globalFilters';
import GlobalFunctions from './common/globalFunctions';
import GlobalPlugins from './common/globalPlugins';

Vue.prototype.$bucketUrl = BUCKET_URL;
Vue.prototype.$assetPath = ASSET_PATH;
Vue.config.productionTip = false;

// sync(store, router);

Vue.use(VueCookies);

Vue.use(GlobalPlugins);
Vue.use(GlobalComponents);
Vue.use(GlobalFunctions);
Vue.use(GlobalFilters);

function createRouter() {
	// eslint-disable-next-line global-require
	return require('./router').default; // Router'ı dinamik olarak yükleyin
}

ApiService.init(store);

store.dispatch('initCompany').then((response) => {
	const router = createRouter();

	initSite({ settings: response, router });
	const vuetify = createVuetify(response);
	sync(store, router);

	new Vue({
		router,
		store,
		vuetify,
		i18n,
		render: (h) => h(App),
		beforeCreate() {
			// API service init

			if (store.getters.isAuthenticated) {
				store.dispatch('getPermission', {
					groupName: 'All',
					viewAs: store.getters.isViewUser,
				});
			}
		},
	}).$mount('#app');
});
