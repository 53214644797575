/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-multi-assign */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import VueGtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';
import { bootstrap, setOptions } from 'vue-gtag';

function initInsider(setting) {
	const insider = {
		active: setting.hasInsider,
		subDomain: setting.insiderSubDomain,
		id: setting.insiderId,
	};

	if (!insider.active) return;

	const externalScript = document.createElement('script');
	externalScript.async = true;
	externalScript.src = `https://${insider.subDomain}.api.useinsider.com/ins.js?id=${insider.id}`;
	document.head.appendChild(externalScript);
}

function initGoogleTagManager(settings, router) {
	if (!settings.gTagManagerId || settings.gTagManagerId === '') return;

	try {
		Vue.use(VueGtm, {
			id: settings.gTagManagerId,
			enabled: true,
			debug: true,
			compatibility: true,
			loadScript: true,
			vueRouter: router,
			ignoredViews: ['home'],
		});
	} catch (error) {
		console.info(`googletagmanager error - ${error}`);
	}
}

function initFavicon(settings) {
	const faviconLink = document.querySelectorAll('link[rel="icon"]');
	faviconLink.forEach((element) => {
		element.setAttribute('href', settings.favicon);
	});
}

function initAnalytics(settings) {
	setOptions({
		config: { id: settings.gTagId },
		pageView: {
			delay: 500,
		},
		appName: settings.companyName,
		pageTrackerScreenviewEnabled: true,
	});
	bootstrap().then(() => {
		console.log('Google Analytics is ready!');
	});
}

function initFacebookPixel(pixel) {
	if (!pixel.isPixel) return;

	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = '2.0';
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
	fbq('init', pixel.pixelCode);
	fbq('track', 'PageView');
}

function initChatBot(chatbot) {
	if (!chatbot.active) return;

	const externalScript = document.createElement('script');
	externalScript.setAttribute('async', '');
	externalScript.setAttribute('src', `${chatbot.code}`);
	document.head.appendChild(externalScript);
}

function checkHasUI(router, settins) {
	const { redirectUrl, hasUI } = settins;

	if (!redirectUrl && !hasUI) {
		router.push({ name: '404' });
	}
}

function initMeta(settings) {
	if (settings.metaDescription?.length > 0) {
		const meta = document.createElement('meta');
		meta.setAttribute('name', 'description');
		meta.setAttribute('content', settings.metaDescription);
		document.head.appendChild(meta);
	}
}

export default function initSite({ settings, router }) {
	document.title = settings.pageTitle ? settings.pageTitle : settings.companyName;

	initInsider(settings);

	initMeta(settings);

	initFavicon(settings);

	initAnalytics(settings);

	initGoogleTagManager(settings, router);
}

export { checkHasUI, initChatBot, initFacebookPixel };
