/* eslint-disable no-unused-vars */
import ApiService from '@/common/api.service';

function findAndRemovePage(pages, pageId) {
	const index = pages.findIndex((page) => page.id === pageId);

	if (index !== -1) {
		// Sayfayı ana dizide bulduk, doğrudan siliyoruz
		pages.splice(index, 1);
		return true;
	}

	// Ana dizide bulunmazsa children dizilerinde arıyoruz
	return pages.some((page) => page.children && findAndRemovePage(page.children, pageId));
}

const endpoints = {
	getList: 'companypage/getlist',
	getPreset: 'companypage/getpreset',
	createPage: 'companypage/insert',
	updatePage: 'companypage/update',
	deletePage: 'companypage/delete',
	checkPageNameAvailability: 'companypage/checkpagenameavailability',
	publish: 'companypage/publish',
};

const state = {
	pages: [],
	totalPages: 0,
	totalRecords: 0,
	currentPage: null,
	presets: {
		parentPages: [],
		pageTypes: [],
		modules: [],
		lastHeaderOrder: 1,
		lastFooterOrder: 1,
	},
};

const getters = {
	getPages(state) {
		return state.pages;
	},
	totalPages(state) {
		return state.totalPages;
	},
	totalRecords(state) {
		return state.totalRecords;
	},
	getPreset(state) {
		return state.presets;
	},
};

const actions = {
	async fetchPages({ commit }, payload) {
		try {
			const response = await ApiService.post(endpoints.getList, payload);
			commit('SET_PAGES', response.data);
			commit('SET_TOTAL_PAGES', response.totalPages || 0);
			commit('SET_TOTAL_RECORDS', response.totalRecords || 0);
		} catch (error) {
			console.error('Error fetching pages:', error);
		}
	},
	async fetchPageById({ commit }, pageId = null) {
		try {
			const response = await ApiService.post(endpoints.getPreset, { id: pageId });
			commit('SET_PAGE', response.page);
			commit('SET_PRESETS', response);
			return response.page;
		} catch (error) {
			console.error('Error fetching page:', error);
			throw error;
		}
	},
	async checkPageNameAvailability({ commit }, payload) {
		try {
			const response = await ApiService.post(endpoints.checkPageNameAvailability, payload);
			return response;
		} catch (error) {
			console.error('Sayfa adı kontrol edilirken hata:', error);
			throw error;
		}
	},
	async savePage({ commit }, pageData) {
		try {
			await ApiService.post(endpoints.updatePage, pageData);
		} catch (error) {
			console.error('Sayfa kaydedilirken hata oluştu:', error);
			throw error;
		}
	},
	async createPage({ commit }, pageData) {
		try {
			await ApiService.post(endpoints.createPage, pageData);
		} catch (error) {
			console.error('Sayfa kaydedilirken hata oluştu:', error);
			throw error;
		}
	},
	async deletePage({ commit }, pageId) {
		try {
			await ApiService.post(endpoints.deletePage, { id: pageId });
			commit('REMOVE_PAGE', pageId);
		} catch (error) {
			console.error('Sayfa silinirken hata oluştu:', error);
			throw error;
		}
	},
	async publishPage({ commit }, payload) {
		try {
			const response = await ApiService.post(endpoints.publish, payload);
			return response;
		} catch (error) {
			console.error('Yayın durumu güncellenirken hata oluştu:', error);
			throw error;
		}
	},
};

const mutations = {
	SET_PAGES(state, pages) {
		state.pages = pages;
	},
	SET_TOTAL_PAGES(state, totalPages) {
		state.totalPages = totalPages;
	},
	SET_TOTAL_RECORDS(state, totalRecords) {
		state.totalRecords = totalRecords;
	},
	SET_PAGE(state, page) {
		state.currentPage = page;
	},
	SET_PRESETS(state, presetData) {
		state.presets.parentPages = presetData.parentPages || [];
		state.presets.pageTypes = presetData.pageTypes || [];
		state.presets.modules = presetData.modules || [];
		state.presets.lastHeaderOrder = presetData.lastHeaderOrder;
		state.presets.lastFooterOrder = presetData.lastFooterOrder;
	},
	REMOVE_PAGE(state, pageId) {
		findAndRemovePage(state.pages, pageId);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
